import React from 'react'
import Wrapper from '../components/Wrapper'
import Forms from '../components/Forms'
import styled from 'styled-components'

export default () => (
  <HeroWrapper>
    <Wrapper>
      <h1>Alabama Association for Institutional Research</h1>
      
      <Inline>
        <Forms />
      </Inline>
      
      {/*
        <embed src="assets/alair_blurb.pdf" width="100%" height="700px" />
      <img src="assets/alair_event.jpg" width="100%" height="100%" alt="ALAIR Conference, March 11th– 13th" title="ALAIR Conference, March 11th– 13th" />
      //greeting card
      //<embed src="assets/2019card.pdf" width="100%" height="600px" />
      <dfn>
        <p>
        We're going back to the beach for our 2020 ALAIR annual conference!
        </p>
        <p>
        Save the dates of March 11-13 for a trip to Orange Beach.
        </p>
        <p>
        Forms will be released in the very near future.
        </p>
        <hr />
      </dfn>
      
    

      <p>
        The Alabama Association for Institutional Research (ALAIR) is an
        organization offering a variety of professional opportunities for
        individuals involved in institutional research and related activities in
        Alabama's institutions of higher education. ALAIR is affiliated with the
        Southern Association for Institutional Research (SAIR) and the
        Association for Institutional Research (AIR).
      </p>
      */}
    </Wrapper>
  </HeroWrapper>
)

const HeroWrapper = styled.div`
  position: relative;
  background: linear-gradient(-75deg, #07588a, #39cccc);
  color: white;
  padding: 1em 0 1em 0;
  /* font-weight: 500; */
  text-align: center;
`

const Inline = styled.div`
  display: block;
  margin: 3em 0 0.75em 0;
`
