import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "2025 Conference SAVE THE DATE.png" }) {
          name
          publicURL
        }
      }
    `}
    render={data => 
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <img 
          src={data.placeholderImage.publicURL} 
          alt={data.placeholderImage.name}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>
    }
  />
)
export default Image
