import React from 'react'

export default props => (
  <div
    style={{
      color: props.color || 'inherit',
      background: props.background,
      padding: props.pad ? '2em 0 0 0' : null,
      textAlign: props.center ? 'center' : null,
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
      }}
    >
      {props.children}
    </div>
  </div>
)
