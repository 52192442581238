import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ConferenceTeaser from '../components/ConferenceTeaser'
import SEO from '../components/seo'

export default function Home() {
  return (
    <Layout top={<Hero />}>
      <SEO
        title="Home"
        keywords={['ALAIR', 'Alabama Association for Institutional Research ']}
      />
      <ConferenceTeaser style={{ background: 'grey' }} />
      <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }} />
    </Layout>
  )
}
