import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import membership from '../../pages/forms/ALAIR Membership Registration Form 2019-Fillable.pdf'

class Listserv extends React.Component {
  render() {
    return (
      <div>
      {/*
        <LeftTheButtons>
          <a
            href="https://alair.org/forms/2020_ALAIR_Conference_Program.pdf"
            target="_self"
          >
            <Membership>Conference Program</Membership>
          </a>
        </LeftTheButtons>
        <RightTheButtons>
          <a
            href="https://alair.org/resources"
            target="_self"
          >
            <Membership>Conference Forms</Membership>
          </a>
        </RightTheButtons>
        */}
      </div>
    )
  }
}

export default Listserv

const CenterTheButtons = styled.div`
  position: relative;
  color: white;
  padding: 1em 0;
  /* font-weight: 500; */
  text-align: center;
`

const RightTheButtons = styled.div`
  position: relative;
  color: white;
  padding: 1em 0;
  /* font-weight: 500; */
  text-align: right;
  float:right;
`

const LeftTheButtons = styled.div`
  position: relative;
  color: white;
  padding: 1em 0;
  /* font-weight: 500; */
  text-align: left;
  float:left;
`

export const Form = styled.form`
  max-width: 31.25em;
  width: 100%;
  select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-image: linear-gradient(to right, #07588a, #39cccc);
    border-image-slice: 1;
    height: 2.2em;
    margin-right: 1em;
    margin-bottom: 1em;
    padding: 0.375em 0.75em;
  }

  input {
    border: 1px solid #ccc;
    border-image: linear-gradient(to right, #07588a, #39cccc);
    border-image-slice: 1;
    box-sizing: border-box;
    line-height: 1.42857143;
    // height: 2.2em;
    margin-bottom: 1em;
    padding: 0.375em 0.75em;
    width: 100%;
  }

  textarea {
    border: 1em solid;
    border-image: linear-gradient(to right, #07588a, #39cccc);
    border-image-slice: 1;
    box-sizing: border-box;
    margin-bottom: 1em;
    padding: 0.375em 0.75em;
    width: 100%;
  }
  .gotcha {
    display: none;
  }
`
const Button = styled.button`
  animation: SpeedyGradient 6s ease infinite;
  background: linear-gradient(to right, #07588A, #39CCCC);
  border: 0px;
  background-size: 400% 400%;
  color: #fff;
  border-radius: 5px;
  padding: .5em 1.5em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .25);
    span {
    background: linear-gradient(to right, #07588A, #39CCCC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-user-select: none;
    user-select: none;
  }

  &:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

    span {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }
  @keyframes SpeedyGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
`

const Membership = styled.button`
animation: SpeedyGradient 3s ease infinite;
margin-left: 1em;
background: #fff;
border: 0px;
background-size: 400% 400%;
color: #07588A;
border-radius: 5px;
text-decoration: none;
padding: .5em 1.5em;
box-shadow: 0 5px 10px rgba(0, 0, 0, .25);
  span {
  background: linear-gradient(to right, #07588A, #39CCCC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

&:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

  span {
    color: #fff;
    -webkit-text-fill-color: #fff;
  }
}
@keyframes SpeedyGradient {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

`
